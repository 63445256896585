
<template>
<div>
  <div v-if="!isLoading" class="row mb-4">
    <div v-if="user" class="col-12">
      <div class="row g-3">
        <div class="col-md-4 order-md-2">
          <div class="position-sticky" style="top: 90px;">
            <div class="card bg-white overflow-hidden">
              <div class="card-body">
                <div class="">
                  <div class="profile-user position-relative d-inline-block mx-auto mb-4">
                    <img @click="showProfileImg(user.image)" :src="absoluteUrl(user.image)"
                      class="rounded-circle avatar-xl img-thumbnail
                       user-profile-image cursor-pointer" alt="user-profile-image" />
                  </div>
                  <h5 class="fs-16 mb-1">
                    {{user.first_name}} {{user.last_name}}
                  </h5>
                  <p class="mb-0"> <span v-if="user.role" class="text-capitalize">{{user.role.name}}</span> / Joined on {{$filters.date(user.created_at)}}</p>
                </div>
                <div v-if="user.id_card" class="mt-4 col-10 col-xxl-4">
                  <div style="border-radius:2rem;" class="card mb-0 text-center">
                    <img @click="showProfileImg(user.id_card)" :src="absoluteUrl(user.id_card)" class="cursor-pointer card-img-top" alt="Group head Id">
                  </div>
                </div>
              </div>
              <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card mb-0 shadow-sm">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="card-title mb-0">User Details</h5>
              <div class="dropdown dropstart no-arrow">
                <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <button @click="popupProfileShow = true" class="dropdown-item"
                    type="button" >
                      Update profile </button>
                  </li>
                  <li>
                    <button @click="popupPasswordShow = true" class="dropdown-item"
                    type="button" >
                      Update password </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body position-relative">
              <div class="row g-5">
                <div class="col-sm-6 col-md-4">
                    <label class="form-label fw-bold">First name</label>
                    <div>{{user.first_name}}</div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-label fw-bold">Middle name</label>
                    <div>{{user.middle_name}}</div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-label fw-bold">Last name</label>
                    <div>{{user.last_name}}</div>
                </div>
                <div class="col-sm-6">
                    <label class="form-label fw-bold">Email</label>
                    <div>{{user.email}}</div>
                </div>
                <div class="col-sm-6">
                    <label class="form-label fw-bold">Phone</label>
                    <div>{{user.mobile}}</div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Residential Address</label>
                  <div style="word-break: break-word;" v-if="user.residential_address">
                    <span class="">{{ user.residential_address.street_address }}</span> <br />
                    <span class="" v-if="user.residential_address.lga">{{ user.residential_address.lga.name }}</span>,
                    <span class="" v-if="user.residential_address.state">{{ user.residential_address.state.name }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="form-label fw-bold">Polling Unit Address</label>
                  <div style="word-break: break-word;" v-if="user.polling_address">
                    <span class="" v-if="user.polling_address.unit">{{ user.polling_address.unit.name }}</span> <br />
                    <span class="" v-if="user.polling_address.ward">{{ user.polling_address.ward.name }}</span>,
                    <span class="" v-if="user.polling_address.lga">{{ user.polling_address.lga.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />

  <b-modal size="lg" v-model="popupProfileShow" hide-footer centered
    title="Update profile" body-class="loading-viewport"
    title-class="fs-6" @hide="initializeAll()">
    <user-update-form v-if="popupProfileShow" @closeMe="initializeAll()"
    :editItem="user" :editMode="true" @updatedItem="updateItem($event)"/>
  </b-modal>

  <b-modal size="lg" v-model="popupPasswordShow" hide-footer centered
    title="Update profile" body-class="loading-viewport"
    title-class="fs-6" @hide="initializeAll()">
    <user-password-form v-if="popupPasswordShow" @closeMe="initializeAll()"
    :editItem="user" :editMode="true" @updatedItem="updateItem($event)"/>
  </b-modal>
</div>
</template>
<script>

import IsLoading from '@/components/IsLoading.vue'
import UserUpdateForm  from "@/components/forms/UserUpdateForm.vue"
import UserPasswordForm from "@/components/forms/UserPasswordForm.vue"

export default {
  name: "user-show",
  components: {
    IsLoading,
    UserUpdateForm,
    UserPasswordForm,
  },
  data() {
    return {
      isLoading: true,
      popupProfileShow: false,
      popupPasswordShow: false
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    user(){
      return this.$store.state.userList.user
    }
  },
  methods:{
    async fetchItem(){
      if(this.$route.params.userId == this.activeUser.id){
        this.$router.push({path: '/account'}); return;
      }
      this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
        .then((response) => {
          this.isLoading = false
          if(!response.data.success){
            this.$router.push({path: '/error-404'})
          }
      })
    },
    initializeAll(){
      this.popupProfileShow = false;
      this.popupPasswordShow = false
    }
  },
  created() {
    this.fetchItem()
  }
}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
.circles-container {
    position: absolute;
    bottom: 0;
    top:0;
    width: 20%;
    right: -20%;
}

.circle-back, .circle-front{
    background-color: #e1f6e2;
    position: relative;
    width: 200%;
    padding-top: 200%;
    padding-bottom: 0;
    border-radius: 100%;
    display: inline-block
}
.circle-back {
    opacity: .6;
    z-index: 3;
    margin-left: -108%;
    margin-top: 0
}

.circle-front {
    opacity: .5;
    margin-bottom: 50%;
    margin-left: -209%;
    z-index: 4
}
</style>
